import React from 'react'
import WhatsAppBot from './pages/bot/WhatsAppBot'
import BulkMsg from './pages/bulk-msg/BulkMsg'
import MsgHistory from './pages/msg-history/MsgHistory'
import OptPlan from './pages/opt-plan/OptPlan'
import PingToAdmin from './pages/ping/PingToAdmin'
import UpdateProfile from './pages/profile/UpdateProfile'
import UserDash from './pages/UserDash'

const UserDashPage = (props) => {
    return (
        <div>
            {props.page === 0 && <UserDash />}
            {props.page === 1 && <BulkMsg />}
            {props.page === 2 && <MsgHistory />}
            {props.page === 3 && <WhatsAppBot />}
            {props.page === 4 && <OptPlan />}
            {props.page === 5 && <PingToAdmin />}
            {props.page === 6 && <UpdateProfile />}
        </div>
    )
}

export default UserDashPage